// Sass Breakpoint Mixin
@mixin breakpoint($point) {
  @if $point == p576 {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == p768 {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == p992 {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == p1200 {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point { // allows custom breakpoints
    @media (min-width: $point) {
      @content;
    }
  }
}

// Position
$position-default: null !default;




// Advanced positioning
// --------------------
@mixin position($type,
								$top: $position-default,
								$right: $position-default,
								$bottom: $position-default,
								$left: $position-default) {
  position: $type;
  $allowed_types: absolute relative fixed;
  @if not index($allowed_types, $type) {
    @warn "Unknown position: #{$type}.";
  }
  @each $data in top $top, right $right, bottom $bottom, left $left {
    #{nth($data, 1)}: nth($data, 2);
  }
}
@mixin absolute($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
  @include position(absolute, $top, $right, $bottom, $left);
}

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss ,only before @import bootstrap variables!"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1550px);

$gutter: $grid-gutter-width;
@import 'fonts';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'reset';

//@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
//@import '../../node_modules/bootstrap/scss/variables';


body {
  min-width: 320px;
  height: 100%;
  font-size: 16px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

img, svg {
  display: block;
}

.bg-layout-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

/* input-placeholder */
input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input::-ms-clear {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  transition: 0.5s ease-in-out;
}
*:before,
*:after {
  z-index: -1;
}
$btn-width: 20px !default;
$btn-height: 50px !default;
$btn-full: $btn-height+$btn-width !default;
$btn-half: $btn-full/2 !default;
$bg-color: #eeeeee !default;


body {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  font-family: Montserrat;
  background: #ffffff;
}
/*---header---------*/
header {
  background: #ffffff;
}

.header, .footer {
  max-width: 1520px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
}
.logo {
}
.list {
  .list-item {
    display: inline-block;
    padding-left: 52px;

    a {
      color: #000000;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.contact-list {
  .contact-item {
    display: inline-block;
    padding-left: 32px;
    position: relative;
    a {
      color: #000000;
      font-size: 18px;
      font-weight: 800;
      line-height: 27px;
    }
  }
}
.contact-item:first-child {
  padding-right: 45px;
}
.contact-item:first-child:before {

  content: '';
  position: absolute;
  left:0;
  top: calc(50% - 10px);
  width: 22px;
  height: 20px;
  z-index: 4;
  background: transparent url(../img/svg/mail.svg) no-repeat 50% 50%;
}
.contact-item:last-child:before {
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 10px);
  width: 20px;
  height: 21px;
  z-index: 4;
  background: transparent url(../img/svg/call-answer.svg) no-repeat 50% 50%;
}
/*--------------*/
/*---banner-----*/
.container.banner-block {
  max-width: 1860px;
  width: 100%;
}
.banner {
  position: relative;
  width: 100%;
  max-width: 1830px;
  padding: 0 45px;
  max-height: 830px;
color: red;
  z-index: 3;
}
.body_slides{
  list-style:none;
  margin:0;
  padding:0;
  z-index:-2;
  background:#000;}
.body_slides,
.body_slides:after{
  position: absolute;
  width:100%;
  height: 830px;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.body_slides:after {
  content: '';
}

.body_slides li{
  width: 100%;
  height: 830px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-animation: anim_slides 20s linear infinite 0s;
  -moz-animation: anim_slides 20s linear infinite 0s;
  -o-animation: anim_slides 20s linear infinite 0s;
  -ms-animation: anim_slides 20s linear infinite 0s;
  animation: anim_slides 20s linear infinite 0s;
}
.body_slides li:nth-child(1){

  background: linear-gradient(to top, #004780 0%, rgba(11, 36, 57, 0) 100%), url(../img/banner1.png);
}
.body_slides li:nth-child(2){
  -webkit-animation-delay: 10.0s;
  -moz-animation-delay: 10.0s;
  background: linear-gradient(to top, #004780 0%, rgba(11, 36, 57, 0) 100%), url(../img/banner2.png)
}
@-webkit-keyframes anim_slides {
  0% {opacity:0;}
  6% {opacity:1;}
  24% {opacity:1;}
  30% {opacity:1;}
  100% {opacity:0;}
}
@-moz-keyframes anim_slides {
  0% {opacity:0;}
  6% {opacity:1;}
  24% {opacity:1;}
  30% {opacity:1;}
  100% {opacity:0;}
}
.content {
  max-width: 1830px;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 830px;
  max-height: 830px;
  position: relative;
  z-index: 3;
  .content-text {
    max-width: 886px;
    margin-bottom: 48px;
    p {
      text-align: center;
      font-size: 50px;
      font-weight: 800;
      color: #ffffff;
    }
  }


  .scroll-btn {
    position: absolute;
    bottom: 32px;
    img {
      width: 33px;
      height: 33px;
    }
  }
}


 /* .btn-11 {
    display: inline-block;
    padding-top: 19px;
    padding-bottom: 16px;
    padding-left: 58px;
    padding-right: 58px;
    border-radius: 25px;
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 13.51px;
    text-transform: uppercase;
  }*/


/*--------------*/
/*----main------*/
/*----services---*/
.services {
  width: 100%;
  padding-top: 92px;
  padding-bottom: 126px;
  display: flex;
  justify-content: space-between;
}
.title-block {
  position: relative;
  padding-left: 34px;
  margin-right: 80px;
  align-self: center;
  width: 518px;
  color: #010101;
  line-height: normal;
}
.title-block:before {
  content: '';
  position: absolute;
  height: 300px;
  left: 0;
  top: -56px;
  border-left: 8px solid #d30606;
}
h2 {
  font-size: 50px;
  font-weight: 800;
}
span {
  font-size: 31px;
  font-weight: 300;

}
.foto-block1 {
  margin-right: 30px;
  div {
    max-width: 295px;
    max-height: 226px;
  }
  div:first-child {
    margin-bottom: 22px;
  }
}
.foto-block2 {
  max-width: 590px;
  max-height: 472px;

}
img {
  width: 100%;
  height: 100%;
}
/*---------------*/
/*----use--------*/
 .use {
   padding-top: 110px;
   padding-bottom: 130px;
   background-color: #f8f8f8;
   h2 {
     margin-bottom: 60px;
     text-align: center;
   }
 }
.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.block-list {
  display: flex;
  justify-content: space-between;
}
.block-list:nth-child(2) {
  flex-direction: row-reverse;
}
.block-list .list-item div {
  position: relative;
  max-width: 510px;
  margin-bottom: 22px;
  height: auto;
}
.block-list .list-item div:before {
  content: '';
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  border-top: 10px solid #014880;

}
.block-list:first-child .list-item div {
  max-width: 337px;
  height: auto;
}
.block-list:last-child .title-item {
  height: 100%;
}

.title-item {
  max-width: 434px;
  height: 100%;
  padding: 30px 44px 30px 39px;
  background-color: #014880;
  color: #ffffff;
  font-size: 30px;
  line-height: 34px;
  font-weight: 800;
}
.block-list {
  margin-bottom: 80px;
  ul{
    padding-left: 22px;
    list-style-type: disc;
    li {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      line-height: 40px;
    }
  }
}
.block-list:last-child {
  margin-bottom: 0;
}
.block-list:first-child ul {
  max-width: 250px;
}
.block-list+.block-list ul {
  max-width: 460px;
}
/*---------------*/
/*----advantages-*/
.advantages .container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.advantages {
  padding-top: 120px;
  padding-bottom: 85px;
  background-color: #ffffff;
    .title-description {
      width: 786px;
      margin-bottom: 62px;
      position: relative;
      padding-left: 34px;
      color: #010101;
      line-height: normal;
    }
    .title-description:before {
      content: '';
      position: absolute;
      height: 64px;
      left: 0;
      top: 0;
      z-index: 3;
      border-left: 8px solid #d30606;
    }
  .advantages-foto {
    max-width: 621px;
    height: auto;
  }
}

.advantages .title-description:before {
  top: 0;
  height: 64px;
}

.list-description {
  display: flex;
  width: 786px;
  max-width: 786px;
  justify-content: space-between;
}
.list-description_item {
  display: flex;
  margin-bottom: 70px;
  align-items: center;
  .item-icon {
    width: 67px;
    margin-right: 25px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .item-text {
    max-width: 230px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: #000000;
  }
}
.list-description_item:last-child {
  margin-bottom: 0;
}

/*----------------*/
/*---portfolio----*/
.portfolio {
  padding-top: 85px;
  padding-bottom: 105px;
  h2 {
    text-align: center;
    margin-bottom: 42px;
  }
}
.portfolio .container {

}
.portfolio-list_row {
  display: flex;
  justify-content: space-between;
}
.portfolio-item {
  max-width: 747px;
  height: auto;
  margin-bottom: 24px;
}
/*---------------*/
/*---contacts----*/
.contacts {
  width: 100%;
  height: 552px;
  position: relative;
  background-color: #f8f8f8;
  .container {
    display: flex;
    align-items: center ;
  }
}
.contact-block {
  padding-top: 110px;
  align-self: center;
  .title-description {
    position: relative;
    padding-left: 34px;
    margin-bottom: 68px;
    color: #010101;
    line-height: normal;
  }
  .title-description:before {

      content: '';
      position: absolute;
      height: 64px;
      left: 0;
      top: 0;
      z-index: 3;
      border-left: 8px solid #d30606;
  }
  .contact-address {
    .list-description_item{
      margin-bottom: 38px;
      .item-icon {
        width: 20px;
        height: 20px;
      }
      .item-text {
        max-width: 630px;
        font-size: 22px;
        font-weight: 400;
      }
    }
    .list-description_item:last-child {
      margin-bottom: 0;
    }

  }
}
.contact-form {
  width: 45.6%;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 55px;
  position: absolute;
  background-color: #014880;
  form {
    padding: 85px 240px 104px 214px;
    border: 2px solid #ffffff;
  }
  div {
    margin-bottom: 15px;
    text-align: center;
  }
  div:last-child {
    margin-bottom: 0;
  }
  h3 {
    width: 310px;
    margin-bottom: 35px;
    color: #ffffff;
    font-size: 34px;
    font-weight: 800;
  }
  input {
    text-align: center;
    width: 270px;
    height: 50px;
    border-radius: 25px;
    background-color: #e7e7e7;
    color: #999999;
  }
   div:last-child {
    margin-bottom: 0;
    margin: 0 auto;
  }

  .consultation-btn .btn-11{
    text-align: center;
    width: 100%;
    height: 51px;
    z-index: 1;
  }
}
/*---------------*/
/*-----map-------*/
#map {
overflow: hidden;
  width: 100%;
  max-width: 1920px;
  height: 560px;

  iframe {
    margin-top: -150px;
    height: 710px;
  }
}
.gm-style .place-card-large {
  display: none;
}
/*---------------*/
/*--------------*/
@media all and (max-width: 1600px) {
  .block-list:first-child .list-item {
    max-width: 337px;
    padding: 0 10px;
    div {
      width: 100%;
      height: auto;
    }
  }
  .title-item {
    margin-right: 10px;
  }
  .block-list .list-item {
    max-width: 510px;
    padding: 0 10px;
    div {
      width: 100%;
      height: auto;
    }
  }
  .block-list:nth-child(2) .title-item {
    margin-right: 0;
    margin-left: 10px;
  }
  .advantages .container {
       display: flex;
       flex-wrap: nowrap;
     }
  .advantages .advantages-foto {
    max-width: 621px;
    margin-right: 20px;
    max-height: 493px;
    height: 100%;
  }
  .advantages-foto+div {
    max-width: 786px;
    width: 100%;
  }
  .list-description_item {
    margin-bottom: 30px;
  }
  .advantages .title-description {
    margin-bottom: 30px;
    max-width: 786px;
    width: 100%;
  }
  .list-description {
    min-width: 300px;
    width: 100%;
  }
  .list-description_item {
    .item-icon {
      width: 47px;
      margin-right: 50px;
    }
    .item-text {

    }

  }
  .portfolio-list_row {
    margin: 0 -10px;
  }
  .portfolio-item {
    padding: 0 10px;
  }
  .contact-form h3 {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-form form {
    padding: 85px 90px 104px 90px;
    border: 2px solid #fff;
    h3 {
      text-align: center;
      font-size: 30px;
    }
  }
}
@media all and (max-width: 1200px) {
  .list .list-item {
    padding-left: 20px;
    a {
      font-size: 16px;
    }
  }
  .contact-list .contact-item a {
    font-size: 16px;
  }
  .contact-item:first-child {
    padding-right: 20px;
  }
  .content .content-text p {
    font-size: 38px;
  }
  h2 {
    font-size: 34px;
  }
  span {
    font-size: 28px;
  }
  .title-block:before {
    top: -30px;
    height: 254px;
  }
  div.title-item {
    height: 100%;
    font-size: 18px;
    line-height: 26px;
    max-width: 298px;
    padding: 30px 14px 30px 14px;
  }
  .block-list:first-child .list-item {
    padding: 0 5px;
  }
  .block-list .list-item div {
    margin-bottom: 10px;
  }
  .block-list ul li {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    color: #000;
  }
  .block-list:last-child .title-item {
    height: 100%;
  }
  .use, .advantages,.portfolio, .services {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .advantages .container {
    display: flex;
    flex-wrap: nowrap;
  }
  .advantages .title-description:before {
    height: 45px;
  }
  .list-description_item .item-text {
    font-size: 18px;
  }
  .list-description_item .item-icon {
    width: 36px;
    margin-right: 10px;
  }
  .list-description_item .item-text {
    font-size: 16px;
    line-height: 26px;
    max-width: 300px;
  }
  .contacts {
    max-height: 430px;
    height: 100%;
  }
  .contact-block .title-description:before {
    height: 45px;
  }
  .contact-block .title-description {
    margin-bottom: 30px;
  }
  .contact-block {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .contact-block .contact-address .list-description_item .item-text {
    font-size: 18px;
    max-width: 480px;
  }
  .contact-block .contact-address .list-description_item {
    margin-bottom: 25px;
  }
  .contact-form form {
    height: 100%;
    padding: 10px 10px 10px 10px;
    border: 2px solid #fff;
  }
  #map {
    width: 100%;
    height: auto;
    max-height: 450px;
  }
}
@media all and(max-width: 991px) {
  .logo {
    display: none;
  }
  .list .list-item {
    padding-left: 9px;
  }
  .menu {
    width: 50%;
  }
  .contact {
    width: 40%;
  }
  .contact-item:first-child {
   padding-right: 0px;
  }
  .contact-list .contact-item a {
    font-size: 16px;
  }
  .content .content-text p {
    font-size: 30px;
  }
  h2 {
    font-size: 28px;
  }
  span {
    font-size: 20px;
  }
  .title-block:before {
    top: -20px;
    height: 180px;
  }
  .title-block {
    width: 518px;
    margin-right: 31px;
    padding-left: 34px;
    color: #010101;
  }
  div.title-item {
    height: 100%;
    max-height: none;
    font-size: 8px;
    line-height: 19px;
    max-width: 140px;
    padding: 10px 7px 10px 11px;
  }
  .block-list:last-child .title-item {
    height: 100%;
  }
  .block-list {
    margin-bottom: 40px;
  }
  .block-list ul li {
    font-size: 14px;
  }
  .advantages .container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .advantages .advantages-foto {
    margin-right: 0;
    max-width: 100%;
  }
  .contacts {
    height: auto;
    max-height: 100%;
    padding: 70px 0;
  }
  .contacts .container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .contact-block {
    width: 100%;
    padding-top: 0;
  }
  .contact-block .contact-address .list-description_item .item-text {
    font-size: 20px;
    max-width: 100%;
  }
  .contact-form {
    position: relative;
    width: 100%;
  }
  .contact-form form {
    height: 100%;
    padding: 30px 10px 30px 10px;
    border: 2px solid #fff;
  }
  #map {
    width: 100%;
    height: auto;
    max-height: 400px;
  }
}
@media all and (max-width: 767px){
  .header, .footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .menu, .contact {
    width: 100%;
    padding: 5px 0;
  }
  .list {
    text-align: center;
  }
  .list .list-item {
    padding: 0 25px;
  }
  .contact-item:first-child {
    padding-right: 50px;
  }
  .content {
    height: 500px;
    max-height: 500px;
  }
  .banner {
    max-height: 500px;
  }
  .body_slides,
  .body_slides:after{
    height: 500px;
  }
  .body_slides li{
    height: 500px;
  }
  .content .content-text p {
    font-size: 24px;
    line-height: 30px;
  }
  .services {
    flex-wrap: wrap;
    justify-content: center;
  }
  .foto-block1 {
    margin-right: 0px;
    margin-bottom: 22px;
    width: 100%;
  }
  .foto-block1 div {
    margin-bottom: 0px;
    max-height: none;
    max-width: none;
  }
  .title-block {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .title-block:before {
    top: 0px;
    height: 94px;
  }
  .use h2 {
    margin-bottom: 42px;
  }
  .block-list{
    flex-wrap: wrap;
  }
  div.title-item {
    margin-right: 0;
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    max-width: none;
    width: 100%;
    height: auto;
  }
  .block-list:last-child .title-item {
    height: auto;
  }
  .block-list .list-item {
    padding: 0;
    margin-bottom: 20px;
    max-width: none;
    width: 100%;
  }
  .block-list:nth-child(2) .title-item {
    margin-left: 0px;
  }
  .block-list .list-item div {
    max-width: none;
    width: 100%;
  }
  .block-list:first-child .list-item {
    width: 100%;
    max-width: none;
    padding: 0;
  }
  .block-list:first-child .list-item div {
    width: 100%;
    max-width: none;
  }
  .block-list:first-child ul {
    max-width: none;
  }
  .advantages .title-description:before {
    height: 32px;
  }
  .list-description_col {
    width: 100%;
  }
  .list-description {
    margin-bottom: 20px;
  }
  .portfolio-list_row {
    flex-wrap: wrap;
  }
  .contact-block .title-description:before {
    height: 32px;
  }
  #map {
    width: 100%;
    height: auto;
    max-height: 350px;
  }

}
@media all and (max-width: 575px) {
  .contact-item:first-child {
    padding-right: 0;
  }
  .contact-list {
    text-align: center;
  }
  .list .list-item {
    padding: 0px 4px;
  }
  .content .content-text p {
    font-size: 18px;
    line-height: 26px;
  }
  h2 {
    font-size: 20px;
  }
  span {
    font-size: 16px;
  }
  .use, .advantages, .portfolio, .services, .contacts {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  div.title-item {
    font-size: 18px;
  }
  .list-description{
    flex-wrap: wrap;
  }
  .contact-block .contact-address .list-description_item .item-icon {
     width: 30px;
    height: 30px;
  }
  .list-description_item .item-icon img {
    width: 30px;
    max-width: 30px;
    height: auto;
  }
  .contact-block .contact-address .list-description_item .item-text {
    font-size: 18px;
  }
  .contact-form form h3 {
    width: auto;
    font-size: 24px;
  }
  .contact-form input {
    width: auto;
  }
  .contact-form {
    padding: 15px;
  }
  /*.contact-form .consultation-btn.send a {
    width: auto;
  }*/
  #map {
    overflow: hidden;
    width: 100%;
    max-width: 1920px;
    height: auto;
    max-height: 300px;
  }
  .footer.header {
    flex-direction: column;
  }
}
/*------------------------*/
/*------------------------*/

/*------------------------*/
/*------------------------*/
/*---btn-----*/


$btn-width: 250px !default;
$btn-height: 80px !default;
$btn-full: $btn-height+$btn-width !default;
$btn-half: $btn-full/2 !default;
$bg-color: #ffffff !default;

*:before,
*:after {
  z-index: -1;
}

.consultation-btn {
  width: 100%;
  max-width: 260px;

}
.btn-11 {
  color: #ffffff;
  position: relative;
  display: block;
  overflow: hidden;
  @include size(100%, $btn-height);
  max-width: 260px;
  margin: 1rem auto;
  border-radius: 25px;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  background: transparent;
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    @include size(20px);
  }
  100% {
    left: 50%;
    @include size($btn-width*20);
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    @include size(20px);
  }
  100% {
    right: 50%;
    @include size($btn-width*20);
  }
}

.btn-11 {
  width: 100%;
  position: relative;
  text-align: center;
  height: 51px;
  line-height: 50px;
  $btn-color: #ffffff;
  $btn-color-dark: #ffffff;
  color: #ffffff;

  &:before,
  &:after {
    @include absolute(50%);
    content: '';
    @include size(20px);
    background-color: #d30606;
    border-radius: 50%;
  }

  &:before {
    left: -20px;
    transform: translate(-50%, -50%);
    // animation: criss-cross-left 0.8s reverse;
  }

  &:after {
    right: -20px;
    transform: translate(50%, -50%);
    // animation: criss-cross-right 0.8s reverse;
  }

  &:hover {
    color: #ffffff;
    border-color: #d30606;
    &:before,
    &:after {
      // @include size($btn-width);
    }

    &:before {
      animation: criss-cross-left 0.8s both;
      animation-direction: alternate;
    }

    &:after {
      animation: criss-cross-right 0.8s both;
      animation-direction: alternate;
    }
  }
}

/*-----------*/