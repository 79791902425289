//@font-face {
//  font-family: 'PTSerifItalic';
//  src: url('../fonts/PTSerifItalic.eot');
//  src: url('../fonts/PTSerifItalic.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/PTSerifItalic.woff') format('woff'),
//  url('../fonts/PTSerifItalic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//
//}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'),
  url('../fonts/Montserrat-ExtraLight.woff') format('woff'),
  url('../fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
  url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
  url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ThinItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-ThinItalic.woff') format('woff'),
  url('../fonts/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-MediumItalic.woff') format('woff'),
  url('../fonts/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
  url('../fonts/Montserrat-Italic.woff') format('woff'),
  url('../fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLight_1.woff2') format('woff2'),
  url('../fonts/Montserrat-ExtraLight_1.woff') format('woff'),
  url('../fonts/Montserrat-ExtraLight_1.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-BlackItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-BlackItalic.woff') format('woff'),
  url('../fonts/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
  url('../fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
  url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-BoldItalic.woff') format('woff'),
  url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.woff2') format('woff2'),
  url('../fonts/Montserrat-Black.woff') format('woff'),
  url('../fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Thin.woff2') format('woff2'),
  url('../fonts/Montserrat-Thin.woff') format('woff'),
  url('../fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
  url('../fonts/Montserrat-Light.woff') format('woff'),
  url('../fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black_1.woff2') format('woff2'),
  url('../fonts/Montserrat-Black_1.woff') format('woff'),
  url('../fonts/Montserrat-Black_1.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-LightItalic.woff') format('woff'),
  url('../fonts/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
  url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

